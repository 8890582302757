import OgImage from '../images/og_page.jpg';
export const META_TAGS = [
    {
        name: 'description',
        content: 'Приложение, с которым вы получите простой и быстрый доступ к миллионам треков, эксклюзивам от топовых артистов и тысячам аудиокниг и подкастов на самые актуальные темы.',
    },
    {
        property: 'og:image',
        content: OgImage.src,
    },
    {
        property: 'og:site_name',
        content: 'VK',
    },
    {
        property: 'og:title',
        content: 'VK Музыка',
    },
    {
        property: 'og:description',
        content: 'Приложение, с которым вы получите простой и быстрый доступ к миллионам треков, эксклюзивам от топовых артистов и тысячам аудиокниг и подкастов на самые актуальные темы.',
    },
];
