import { callAnalyticVisit } from '@common/utils/Analytics';
import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
const Gate = createGate({});
const sendAnalyticFx = createEffect(() => {
    callAnalyticVisit();
});
sample({ clock: Gate.open, target: [sendAnalyticFx] });
export const appModelPrivate = {
    Gate,
};
